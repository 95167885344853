@import '_settings.scss';

.body {
    font-size: 14px;
    color: #53534a;
}



/*Bootstrap Overrides*/

#bsor {
    font-size: 14px !important;
    color: #53534a !important;
}

#bsor .text-primary {
    color: #53534a !important;
}

#bsor .text-secondary {
    color: #288ad0 !important;
}

#bsor h3 {
    font-size: 1.25rem !important;
    font-weight: normal !important;
    margin-left: 0 !important;
}

#bsor a, a:hover, a:focus {
    text-decoration: none;
}

#bsor .btn:hover {
    color: #fff;
}

/*End Overrides*/


/*h2 {
    font-size: 30px;
    color: #3F8EC7;
    margin: 10px;
}

h3 {
    font-size: 20px;
    color: #3F8EC7;
    margin: 5px;
}*/

.rule {
    background-color: #CCCCCC;
    height: .3em;
    margin: 20px 0;
}

.red {
    color: #CB1F1F;
}

.blue {
    color: #3F8EC7;
}


.margin-left-none {
    margin-left: 0;
}



/* Set padding to keep content from hitting the edges */
.body-content {
    padding-left: 15px;
    padding-right: 15px;
}

/* Override the default bootstrap behavior where horizontal description lists 
   will truncate terms that are too long to fit in the left column 
*/
.dl-horizontal dt {
    white-space: normal;
}


/* ---------- EXTENSIONS RS ---------- */

noShadow {
    text-shadow: none;
}

a.noShadow {
    text-shadow: none;
}

a:hover.noShadow {
    text-shadow: none;
}

a:active.noShadow {
    text-shadow: none;
}

a:visited.noShadow {
    text-shadow: none;
}

.validation-summary-valid {
    display: none;
}

.validation-summary-errors {
    min-width: 400px;
    max-width: 615px;
    padding: 10px;
    border: 1px solid #cb1f1f;
    background-color: #fdf3f3;
    color: #cb1f1f;
    margin-bottom: 50px;
}

.errMsgBox {
    max-width: 600px;
    padding: 10px;
    border: 1px solid #cb1f1f;
    background-color: #fdf3f3;
    color: #cb1f1f;
}

/* ---------- EXTENSIONS TIPR ---------- */
input:not([type="file"]).error,
textarea.error,
select.error {
    border: 1px solid red !important;
}

input:not([type="file"]).no-error,
textarea.no-error,
select.no-error {
    border: 1px solid green !important;
}

div.error-field {
    color: red;
    font-size: small;
}

.si {
    font-family: Consolas, sans-serif, 'Segoe UI';
    font-size: smaller;
}

/* ---------- EXTENSIONS RS ---------- */


/* New Styles */

.terms {
    padding: 30px 50px 50px;
}

.form-group {
    & input.form-control.input-validation-error {
        border-color: $alert;

        &:focus {
            border-color: $primary-color;
        }
    }
}

.validation {
    margin-top: 5px;
    color: $alert;
    text-align: right;
    padding-left: 15px;

    .error {
        margin-top: 0;
    }
}

.alert {
    padding: 0;
    margin-bottom: 0;
    color: $alert;
    background-color: transparent;
    border-color: transparent;

    & ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
}

.alert:has(.validation-summary-errors) + form {
    & > .form-group {
        & > .form-control:not(:focus), > .input-group:not(:focus-within) {
            border-color: $alert;
        }
    }
}

div.alert ~ div #RequestData_Username.form-control {
    border: 1px solid $alert !important;
    box-shadow: none;
}

div.alert ~ div .input-group {
    border: 1px solid $alert !important;
    box-shadow: none !important;
}

.credential-label::after {
    content: "*";
    color: #CB1F1F;
    margin-left: .25rem
}

.login-register {
    max-width: 1150px;
    min-width: 1150px;
    width: 1150px;
}

.left-side {
    max-width: 500px;
    min-width: 500px;
    width: 500px;
    min-height: 628px;
}

.title {
    text-align: left;
}

.label-min-width {
    min-width: 350px;
}

.input-min-width {
    min-width: 350px;
    max-width: 350px;
}

.form-group-30-410 {
    height: 30px;
    width: 410px;

    .link-container {
        height: 50px;
        text-align: right;
        margin-right: 30px;

        .forgot-password-link {
            height: 50px;
        }
    }
}

.form-group-30-500 {
    height: 30px;
    width: 500px;

    .button-container {
        height: 30px;
    }
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-group-40-380 {
    height: 40px;
    padding-top: 10px;
    width: 380px;

    .line-container {
        float: left;
        width: 155px;
        margin-left: 10px;

        .line {
            background: #ccc;
            border: none;
            height: 1px;
            width: 95%;
            margin: auto;
        }
    }

    .or-text {
        float: left;
        width: 30px;
        margin-left: 10px;
    }
}

.form-group-auto-380 {
    height: auto;
    width: 380px;
}

.form-group-auto-500 {
    height: auto;
    width: 500px;
}

.form-group-auto-410 {
    height: auto;
    width: 410px;

    .help-link-container {
        height: 50px;
        text-align: right;
        margin-right: 30px;

        .help-link {
            height: 50px;
        }
    }
}

.right-side {
    width: 670px;

    .image-container {
        margin-top: 10px;

        .image {
            width: auto;
            margin-left: -20px;
        }
    }
}

.lang-container {
    max-width: 1070px;
    position: relative;
    padding: 30px 50px 50px;
}

.lang-row {
    display: inline-block;
    float: right;
    margin-right: 10px;
}

.lang-select,
.lang-label {
    float: right;
}

@media only screen and (min-width: 640px) {
    .Footer {
        margin-top: 0px !important;
    }
}
