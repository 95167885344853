.body {
  color: #53534a;
  font-size: 14px;
}

#bsor {
  color: #53534a !important;
  font-size: 14px !important;
}

#bsor .text-primary {
  color: #53534a !important;
}

#bsor .text-secondary {
  color: #288ad0 !important;
}

#bsor h3 {
  margin-left: 0 !important;
  font-size: 1.25rem !important;
  font-weight: normal !important;
}

#bsor a, a:hover, a:focus {
  text-decoration: none;
}

#bsor .btn:hover {
  color: #fff;
}

.rule {
  background-color: #ccc;
  height: .3em;
  margin: 20px 0;
}

.red {
  color: #cb1f1f;
}

.blue {
  color: #3f8ec7;
}

.margin-left-none {
  margin-left: 0;
}

.body-content {
  padding-left: 15px;
  padding-right: 15px;
}

.dl-horizontal dt {
  white-space: normal;
}

noShadow, a.noShadow, a:hover.noShadow, a:active.noShadow, a:visited.noShadow {
  text-shadow: none;
}

.validation-summary-valid {
  display: none;
}

.validation-summary-errors {
  color: #cb1f1f;
  background-color: #fdf3f3;
  border: 1px solid #cb1f1f;
  min-width: 400px;
  max-width: 615px;
  margin-bottom: 50px;
  padding: 10px;
}

.errMsgBox {
  color: #cb1f1f;
  background-color: #fdf3f3;
  border: 1px solid #cb1f1f;
  max-width: 600px;
  padding: 10px;
}

input:not([type="file"]).error, textarea.error, select.error {
  border: 1px solid red !important;
}

input:not([type="file"]).no-error, textarea.no-error, select.no-error {
  border: 1px solid green !important;
}

div.error-field {
  color: red;
  font-size: small;
}

.si {
  font-family: Consolas, sans-serif, Segoe UI;
  font-size: smaller;
}

.terms {
  padding: 30px 50px 50px;
}

.form-group input.form-control.input-validation-error {
  border-color: #eb0000;
}

.form-group input.form-control.input-validation-error:focus {
  border-color: #8bbc07;
}

.validation {
  color: #eb0000;
  text-align: right;
  margin-top: 5px;
  padding-left: 15px;
}

.validation .error {
  margin-top: 0;
}

.alert {
  color: #eb0000;
  background-color: #0000;
  border-color: #0000;
  margin-bottom: 0;
  padding: 0;
}

.alert ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.alert:has(.validation-summary-errors) + form > .form-group > .form-control:not(:focus) {
  border-color: #eb0000;
}

.alert:has(.validation-summary-errors) + form > .form-group > .input-group:not(:focus-within) {
  border-color: #eb0000;
}

div.alert ~ div #RequestData_Username.form-control {
  box-shadow: none;
  border: 1px solid #eb0000 !important;
}

div.alert ~ div .input-group {
  box-shadow: none !important;
  border: 1px solid #eb0000 !important;
}

.credential-label:after {
  content: "*";
  color: #cb1f1f;
  margin-left: .25rem;
}

.login-register {
  width: 1150px;
  min-width: 1150px;
  max-width: 1150px;
}

.left-side {
  width: 500px;
  min-width: 500px;
  max-width: 500px;
  min-height: 628px;
}

.title {
  text-align: left;
}

.label-min-width {
  min-width: 350px;
}

.input-min-width {
  min-width: 350px;
  max-width: 350px;
}

.form-group-30-410 {
  width: 410px;
  height: 30px;
}

.form-group-30-410 .link-container {
  text-align: right;
  height: 50px;
  margin-right: 30px;
}

.form-group-30-410 .link-container .forgot-password-link {
  height: 50px;
}

.form-group-30-500 {
  width: 500px;
  height: 30px;
}

.form-group-30-500 .button-container {
  height: 30px;
}

.flex-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.form-group-40-380 {
  width: 380px;
  height: 40px;
  padding-top: 10px;
}

.form-group-40-380 .line-container {
  float: left;
  width: 155px;
  margin-left: 10px;
}

.form-group-40-380 .line-container .line {
  background: #ccc;
  border: none;
  width: 95%;
  height: 1px;
  margin: auto;
}

.form-group-40-380 .or-text {
  float: left;
  width: 30px;
  margin-left: 10px;
}

.form-group-auto-380 {
  width: 380px;
  height: auto;
}

.form-group-auto-500 {
  width: 500px;
  height: auto;
}

.form-group-auto-410 {
  width: 410px;
  height: auto;
}

.form-group-auto-410 .help-link-container {
  text-align: right;
  height: 50px;
  margin-right: 30px;
}

.form-group-auto-410 .help-link-container .help-link {
  height: 50px;
}

.right-side {
  width: 670px;
}

.right-side .image-container {
  margin-top: 10px;
}

.right-side .image-container .image {
  width: auto;
  margin-left: -20px;
}

.lang-container {
  max-width: 1070px;
  padding: 30px 50px 50px;
  position: relative;
}

.lang-row {
  float: right;
  margin-right: 10px;
  display: inline-block;
}

.lang-select, .lang-label {
  float: right;
}

@media only screen and (min-width: 640px) {
  .Footer {
    margin-top: 0 !important;
  }
}

/*# sourceMappingURL=site.css.map */
